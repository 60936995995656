<template>
  <CChartBar
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="xAxes"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'CChartBarExample',
  components: { CChartBar },
  props: {
    label: {
      default: null,
      type: String,
      required: true
    },
    data: {
      default: () => [10, 22, 34, 46, 58, 62, 73, 80, 91, 102, 110, 123],
      type: Array,
      required: true
    },
    xAxes: {
      default: 'months',
      type: Array,
      required: true
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: this.label,
          // backgroundColor: '#f87979',
          backgroundColor: 'rgba(102,178,255,0.9)',
          data: this.data
        }
      ]
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    },
  }
}
</script>
