<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="3">
						<CInput
							type="date"
							label="Date From"
							placeholder=""
							description="Masukkan tanggal awal pencarian."
							:value.sync="start_date"
							required
						/>
					</CCol>
          <CCol sm="3">
						<CInput
							type="date"
							label="Date To"
							placeholder=""
							description="Masukkan tanggal akhir pencarian."
							:value.sync="end_date"
              @change="getData(site_id, selected)"
							required
						/>
					</CCol>
          <CCol sm="3">
            <CSelect 
              :disabled="role > 2"
							:value.sync="site_id"
							name="site_id"
							label="Data Center"
							placeholder="Pilih Data center"
							:options="sites"
              @change="siteChange($event)"
						/>
          </CCol>
          <CCol sm="3">
            <CSelect 
							:value.sync="room_id"
							name="room_id"
							label="Room"
							placeholder="Pilih Ruangan"
							:options="rooms"
              @change="roomChange($event)"
						/>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="6">
            <h4 id="traffic" class="card-title mb-0">Visit at {{site_name}} - {{room_name}}</h4>
            <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
          </CCol>
          <CCol sm="6" class="d-none d-md-block">
            <!-- <CButton color="primary" class="float-right">
              <CIcon name="cil-cloud-download"/>
            </CButton> -->
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-primary"
                v-for="(value, key) in ['day', 'month', 'year']"
                :key="key"
                class="mx-0"
                :pressed="value === selected ? true : false"
                @click="getData(site_id, room_name, selected = value)"
              >
                {{value}}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
        <CChartBarExample style="height:460px; margin-top:40px;" :label="room_name" :data="data" :xAxes="axes" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment';
import http from '@/utils/http-common';

import CChartBarExample from '@/views/charts/CChartBarExample'

export default {
  name: 'TrendRoom',
  components: {
    CChartBarExample
  },
  data () {
    return {
      user_site: JSON.parse(localStorage.getItem('user'))['user']['site_id'],
			role: +localStorage.getItem('role'),
      selected: 'month',
      site_id: 1,
      site_name: '',
      sites: [],
      room_id: 1,
      room_name: '',
      rooms: [],
      data: [],
      axes: [],
      start_date: '',
      end_date: this.date2Str(new Date(), 'yyyy-MM-dd')
    }
  },
  methods: {
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    date2Str(x, y) {
    	const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    	};
    	y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    	});

    	return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length)
    	});
		},
    lastDays() {
      const dt = new Date();
      dt.setDate(dt.getDate() - 30);
      this.start_date = this.date2Str(dt, 'yyyy-MM-dd');
    },
    getSiteId() {
      this.site_id = this.role <= 2 ? 1 : +this.user_site;
      this.room_id = 1;
    },
    getData(id, name, summary) {
      let self = this;

      let items = [];
      let axes = [];

      let start;
      let end;
			return http.get('/report/trend-sites?site_id=' + id + '&room_name=' + name + '&summary=' + summary)
			.then(function (response) {
				let data = response.data.data;

        console.log(data)

        switch(summary) {
          case 'day':
            // code block
            start = new Date(self.start_date).getTime();
            end = new Date(self.end_date).getTime();

            data = data.filter(item => {
              let date = new Date(item.date).getTime();
              return date >= start && date <= end;
            })
            break;
          case 'month':
            // code block
            start = new Date(self.start_date).getMonth()+1;
            end = new Date(self.end_date).getMonth()+1;

            data = data.filter(item => {
              // console.log(item.month)
              if (start > end) {
                return item.month >= end && item.month <= start;
              } else {
                return item.month >= start && item.month <= end;
              }
            })
            break;
          default:
            // code block
            start = new Date(self.start_date).getFullYear();
            end = new Date(self.end_date).getFullYear();

            data = data.filter(item => {
              return item.year >= start && item.year <= end;
            })
        }

        data.forEach(item => {
          items.push(item.total);

          switch(summary) {
            case 'day':
              // code block
              axes.push(item.date)
              break;
            case 'month':
              // code block
              axes.push(self.date( new Date('2021-' + item.month + '-1'), 'MMMM'))
              break;
            default:
              // code block
              axes.push(item.year)
            }
        })
        self.axes = axes;
        self.data = items;
			}).catch(function (error) {
				console.log(error);
			});
    },
    getSites() {
			const self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
    siteChange(event) {
      this.rooms = [];
      this.site_id = event.target.value;
      this.siteDetail(event.target.value);
      this.room_name = 'Ruangan Belum Dipilih..'
      this.getRooms(event.target.value);
      this.selected = 'month';
      this.data = [];
      this.axes = [];
    },
    siteDetail(id) {
      const self = this;
			return http.get('/sites/' + id)
			.then(function (response) {
				self.site_name = response.data.data['site_name'];
			}).catch(function (error) {
				console.log(error);
			});
    },
    roomDetail(id) {
      const self = this;
			return http.get('/rooms/' + id)
			.then(function (response) {
				self.room_name = response.data.data['room_name'];
			}).catch(function (error) {
				console.log(error);
			});
    },
    getRooms(id){
			let self = this;
			return http.get('/rooms/site/' + id)
			.then(function (response) {
				const data = response.data.room;
        
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['room_name'];
					items['value'] = data[i]['id'];
					self.rooms.push(items);
				}
				
			}).catch(function (error) {
				console.log(error);
			});
		},
    roomChange(event) {
      this.data = [];
      this.axes = [];
      this.room_name = 'Mengubah Ruangan..';
      this.roomDetail(event.target.value).then(() => {
        this.getData(this.site_id, this.room_name, this.selected);
      });
      this.selected = 'month';
    }
  },
  mounted: function() {
    this.getSiteId();
    this.lastDays();
		this.getSites();
    this.siteDetail(this.site_id);
    this.getRooms(this.site_id);
    this.roomDetail(this.room_id).then(() => {
      this.getData(this.site_id, this.room_name, this.selected);
    });
	}
}
</script>

<style>

</style>
